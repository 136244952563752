import React, { useState, useEffect } from "react";
import "./App.css";
import { calculateTotalTaxBurden, calculateIncomeTax, calculateSalesTax, calculateEstateTax } from './calculateTax';
import { verifyStateTaxData } from './VerifyStateTaxData';
import stateTaxDataJsonFile from './StateTaxData2024.json';

// some example use cases, will need to be updated in future versions
const exampleUseCases = {
  newRetiree: {
    laborIncome: 0,
    retirementIncome: 50000,
    socialSecurity: 30000,
    capitalGains: 10000,
    interestDividends: 5000,
    estateValue: 1000000,
    groceryExpenses: 400,
    retailPurchases: 300,
    maritalStatus: 'married',
    estateYears: 15
  },
  workingProfessional: {
    laborIncome: 100000,
    retirementIncome: 0,
    socialSecurity: 0,
    capitalGains: 5000,
    interestDividends: 1000,
    estateValue: 500000,
    groceryExpenses: 800,
    retailPurchases: 500,
    maritalStatus: 'married',
    estateYears: 35
  },
  youngSingleProfessional: {
    laborIncome: 75000,
    retirementIncome: 0,
    socialSecurity: 0,
    capitalGains: 2000,
    interestDividends: 500,
    estateValue: 100000,
    groceryExpenses: 400,
    retailPurchases: 300,
    maritalStatus: 'single',
    estateYears: 50
  },
  middleAgedCouple: {
    laborIncome: 120000,
    retirementIncome: 0,
    socialSecurity: 0,
    capitalGains: 15000,
    interestDividends: 2000,
    estateValue: 800000,
    groceryExpenses: 1000,
    retailPurchases: 600,
    maritalStatus: 'married',
    estateYears: 35
  }
};

// Function for camel casing
function toCamelCase(inputString) {
  return inputString
    // Split into words
    .split(/[^a-zA-Z0-9]+/)
    // Capitalize first letter of each word except the first
    .map((word, index) => {
      if (index === 0) return word.toLowerCase();
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    // Join the words to form a camelCase string
    .join('');
}

// Function to convert TSV to JSON
function tsvToJson(tsv) {
  const rows = tsv.trim().split('\n');
  const headers = rows[0].split('\t');
  const dataJson = [];

  for (let i = 1; i < rows.length; i++) {
    const values = rows[i].split('\t');
    const obj = {};

    headers.forEach((header, index) => {
      const camelCaseHeader = toCamelCase(header);
      obj[camelCaseHeader] = values[index];
    });

    dataJson.push(obj);
  }
  return dataJson;
}



function formatCurrency(number) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(number);
}

function App() {

  const [stateTaxDataJson, setStateTaxDataJson] = useState([]);
  const [formData, setFormData] = useState({
    estateValue: "",
    laborIncome: "",
    retirementIncome: "",
    socialSecurity: "",
    capitalGains: "",
    interestDividends: "",
    groceryExpenses: "",
    retailPurchases: "",
    maritalStatus: "",
    estateWeighting: 0.05
  });

  const [results, setResults] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'state', direction: 'ascending' });

  useEffect(() => {
    // Since the data is already imported, no need to fetch
    setStateTaxDataJson(stateTaxDataJsonFile);
  }, []);

  useEffect(() => {
    // Check if the app is running locally or in production
    const isLocal = window.location.hostname === 'localhost';
    if (!isLocal) {
      return;
    }

    // Fetch the TSV file from the correct path
    fetch('/StateTaxData2024.tsv')
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.text();
      })
      .then((data) => {
        // Convert the TSV data to JSON
        const jsonResult = tsvToJson(data);
        console.log(jsonResult);
      })
      .catch((error) => console.error('Error loading TSV file:', error));
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Example calculation function (replace with your own logic)
    const calculations = calculateTax(formData);

    setResults(calculations);
  };

  const handleUseCaseChange = (e) => {
    const selectedUseCase = e.target.value;
    if (exampleUseCases[selectedUseCase]) {
      setFormData({
        ...formData,
        ...exampleUseCases[selectedUseCase]
      });
    }
  };

  // Dummy function, replace with your calculation logic
  const calculateTax = (submittedData) => {
    const states = stateTaxDataJson;
    verifyStateTaxData(states);
    console.log(states);
    return states.map((state) => ({
      state: state.state,
      totalTax: calculateTotalTaxBurden(submittedData, state),
      incomeTax: calculateIncomeTax(submittedData, state),
      salesTax: calculateSalesTax(submittedData, state),
      estateTax: calculateEstateTax(submittedData, state),
    }));
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleYearsChange = (e) => {
    const years = Number(e.target.value);
    const weight = calculateEstateWeighting(years);

    setFormData({
      ...formData,
      estateYears: years,
      estateWeighting: weight, // Set the calculated estate weighting
    });
  };

  // Function to calculate the estate weighting based on years
  function calculateEstateWeighting(years) {
    // Example: Weight decreases as the number of years increases
    return 1 / years; // Adjust this formula as per your desired model
  }

  const sortedResults = React.useMemo(() => {
    const sortableItems = [...results];
    sortableItems.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    return sortableItems;
  }, [results, sortConfig]);

  return (
    <div className="App">

      <h2>State Tax Estimator</h2>
      <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="useCase">Select a Use Case:</label>
        <select id="useCase" name="useCase" onChange={handleUseCaseChange}>
          <option value="">--Select a Use Case--</option>
          <option value="newRetiree">New Retiree</option>
          <option value="workingProfessional">Working Professional with Family</option>
          <option value="youngSingleProfessional">Young Single Professional</option>
          <option value="middleAgedCouple">Middle-aged Couple</option>
        </select>
      </div>
        <h3>Income</h3>
        <div className="form-group">
          <label htmlFor="laborIncome">Annual Labor Income (required):</label>
          <input
            type="number"
            id="laborIncome"
            name="laborIncome"
            value={formData.laborIncome}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="retirementIncome">Annual Private Retirement Income:</label>
          <input
            type="number"
            id="retirementIncome"
            name="retirementIncome"
            value={formData.retirementIncome}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="socialSecurity">Annual Social Security Benefits:</label>
          <input
            type="number"
            id="socialSecurity"
            name="socialSecurity"
            value={formData.socialSecurity}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="capitalGains">Annual Capital Gains Income:</label>
          <input
            type="number"
            id="capitalGains"
            name="capitalGains"
            value={formData.capitalGains}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="interestDividends">Annual Interest and Dividends Income:</label>
          <input
            type="number"
            id="interestDividends"
            name="interestDividends"
            value={formData.interestDividends}
            onChange={handleChange}
          />
        </div>

        <h3>Wealth, Expenses, and Marital Status</h3>

        <div className="form-group">
          <label htmlFor="estateValue">Estate Value:</label>
          <input
            type="number"
            id="estateValue"
            name="estateValue"
            value={formData.estateValue}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="groceryExpenses">Monthly Grocery Expenses:</label>
          <input
            type="number"
            id="groceryExpenses"
            name="groceryExpenses"
            value={formData.groceryExpenses}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="retailPurchases">Monthly Non-Grocery Retail Purchases:</label>
          <input
            type="number"
            id="retailPurchases"
            name="retailPurchases"
            value={formData.retailPurchases}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="maritalStatus">Marital Status:</label>
          <select
            id="maritalStatus"
            name="maritalStatus"
            value={formData.maritalStatus}
            onChange={handleChange}
            required
          >
            <option value="">Select marital status</option>
            <option value="single">Single</option>
            <option value="married">Married</option>
          </select>
        </div>

        <h3>Display Options</h3>

        <div className="form-group">
          <label htmlFor="estateYears">Years Until Inheritance Event:</label>
          <input
            type="range"
            id="estateYears"
            name="estateYears"
            min="1"
            max="60"
            value={formData.estateYears || 10} // Default to 10 years
            onChange={handleYearsChange}
          />
          <span>{formData.estateYears || 10} years</span>
        </div>

        <button className="elementor-button no-border" type="primary">Submit</button>
      </form>

      {sortedResults.length > 0 && (
        <table>
          <thead>
            <tr>
              <th onClick={() => requestSort('state')}>State {sortConfig.key === 'state' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : null}</th>
              <th onClick={() => requestSort('totalTax')}>Total Tax Burden {sortConfig.key === 'totalTax' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : null}</th>
              <th onClick={() => requestSort('incomeTax')}>Income Tax {sortConfig.key === 'incomeTax' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : null}</th>
              <th onClick={() => requestSort('salesTax')}>Sales Tax {sortConfig.key === 'salesTax' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : null}</th>
              <th onClick={() => requestSort('estateTax')}>Estate Tax {sortConfig.key === 'estateTax' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : null}</th>
            </tr>
          </thead>
          <tbody>
            {sortedResults.map((result, index) => (
              <tr key={index}>
                <td>{result.state}</td>
                <td>{formatCurrency(result.totalTax)}</td>
                <td>{formatCurrency(result.incomeTax)}</td>
                <td>{formatCurrency(result.salesTax)}</td>
                <td>{formatCurrency(result.estateTax)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default App;
